:root {
    --rotation : 50deg;
    --baytmblue: #008ecf;
    --baytmblue-disabled: #7F9EC6;
    --baytmblue-dark: #1F2933;
    --baytmblue-grey: #426189;
    --baytmblue-light: #FCFDFF;
    --baytmblue-light-hover: #ECF4FF;
    --baytmblue-light-hover-outline: #B5D4FC;
    --slider-tooltip-bg: var(--baytmblue);
    --slider-connect-bg: var(--baytmblue);
    --font: 'MuseoSans300', sans-serif !important;
    --btn-background: #008ecf;
    --btn-background-hover: #001f47;
    --btn-shadow: none;
    --btn-shadow-hover: none;
    --btn-shadow-active: none;
    --btn-border: #fff;
    --btn-text: #fff;
    --btn-text-hover: #fff;
}

// Colors
$dark:      #1F2933;
$grey:      #F5F5FF;
$light:     #FFFFFF;
$red:       #fb4202;
$hmBlue:    #89eec1;

// Font
$bodyFontSize: 16px;
$font-size-base: 1rem;
$line-height-base: 1.2;
$font-family-base: PX Grotesk Light, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

// Grid / Container
$containerWidth: 1170px;
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: $containerWidth,
    xxl: 1320px
);
$grid-gutter-width: 40px;

// Logo
$logoWidth: 92px;
$logoMaxHeight: 69px;
$logoMobileWidth: 60px;
$logoMobileMaxHeight: 45px;
$logoPadding: 10px;

// Selection
$selectionBg: $red;
$selectionColor: $light;

// Nav
$mobileNavHeight: 88px;
$desktopNavHeight: 112px;

// Nav Burger
$burgerWidth: 22px;
$burgerHeight: 2px;
$burgerHoverActiveBG: $red;
$burgerLineDistance: 6px;

$toggleWidth: auto;
$toggleHeight: 25px;
$toggleoffsetRight: 25px;

// Mixins
@mixin darkBackground {
    background: $dark;
}

@mixin lightBackground {
    background: $light;
}

@mixin imageBorder {
    border: 4px solid $light;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 24%);

    @include media-breakpoint-up(lg) {
        border: 8px solid $light;
        border-radius: 32px;
        box-shadow: 0 0 160px 0 rgb(0 0 0 / 24%);
    }
}

@mixin standardTransition($property, $timing: .3s ease-in-out) {
    transition: $property $timing;
}
