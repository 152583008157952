@import 'hmVars';

body {
    background: #393737 !important;
}

.login h1 a {
    background-image: url('../../../img/hm.svg') !important;
    background-size: 200px 55px !important;
    padding-bottom: 45px;
    width: 255px !important;
}

body.login div#login p#nav {
    margin: 15px 0 0;
    padding: 0;
    display: inline-block;
    text-shadow: none;
}

body.login div#login p#nav:after {
    content: '\00a0|\00a0';
}

body.login div#login p#backtoblog {
    margin: 0;
    padding: 0;
    display: inline-block;
    text-shadow: none;
}

body.login div#login p#nav a,
body.login div#login p#nav a:active,
body.login div#login p#nav a:visited,
body.login div#login p#backtoblog a,
body.login div#login p#backtoblog a:active,
body.login div#login p#backtoblog a:visited
body.login div#login .privacy-policy-page-link a {
    text-decoration: none;
    color: #fff !important;
}

body.login form {
    border-radius: 2px;
    box-shadow: none;
    border: 0;
}
